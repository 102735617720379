<template>
    <div>
        <el-pagination class="pages-center" ref="pages" :current-page="currentPage"
                       layout="prev, pager, next, jumper,slot"
                       :total="total" @current-change="handleCurrentChange">
<!--            <div class="go-circle" @click="handleClick">GO</div>-->
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "pagination",
        props: ['total'],
        data() {
            return {
                currentPage: 1,
                isChanged: false
            }
        },
        methods: {
            handleCurrentChange(page) {
                this.currentPage = page;
                this.$emit('current-change', page)
            },
            handleClick() {
                this.$refs.pages.handleCurrentChange
            }
        }
    }
</script>

<style scoped lang="scss">
    .pages-center {
        display: flex;
        justify-content: center;
        align-items: center;

        .go-circle {
            width: 20px;
            height: 20px;
            background: #2DC079;
            color: #fff;
            border-radius: 50%;
            font-size: 12px;
            line-height: 20px;
            margin-left: 10px;
            cursor: pointer;
        }
    }
</style>